import React, { Fragment, useContext, useState } from "react";

import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

import arrow_icon from "../../assets/images/icon/arrow.svg";

import portfolio_big from "../../assets/images/portfolio/sin1.jpg";
import portfolio1 from "../../assets/images/details/ss1.jpg";
import portfolio2 from "../../assets/images/details/ss2.jpg";
import portfolio3 from "../../assets/images/details/ss3.jpg";
import makeenImg from '../../assets/images/makeen.PNG'
import { useParams } from "react-router-dom";
import AppContext from "../../context/AppContext";
import FooterForm from "../Footer/FooterForm";
import FooterTwo from "../Footer/FooterTwo";
import HeaderNavigation from "../HeaderNavigation/HeaderNavigation";
const PortfolioDetails = () => {

    const { id } = useParams();
    console.log(id)
    const { photos } = useContext(AppContext)
    console.log("Photos:", JSON.stringify(photos, null, 2));
    const photo = photos.find(p => p.id === id);

    console.log("Selected Photo:", JSON.stringify(photo, null, 2));
    // if (!photo) {
    //     return <div>Photo not found</div>; // Handle case where photo is not found
    // }
    var settings = {
        dots: true,
        centerMode: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    centerMode: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    centerMode: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    };

    return (
        <Fragment>
            <HeaderNavigation />
            {/* Start Page Banner Area  */}
            {/* <section className="breadcrumb-wrapper">
                <h1>Founder of Kayan</h1>
                <div className="inner-box">
                    <div className="prev-button">
                        <a href="/">
                            <img className="svg" src={arrow_icon} alt="" />
                            <span>PREVOIUS PROJECT</span>
                        </a>
                    </div>
                    <div className="portfolio-intro">
                        <p>
                            CLIENT : <a href="/">THEMECOP</a> | YEAR : <a href="/">2014</a>
                        </p>
                    </div>
                    <div className="next-button">
                        <a href="/">
                            <img className="svg" src={arrow_icon} alt="" />
                            <span>NEXT PROJECT</span>
                        </a>
                    </div>
                </div>
            </section> */}
            {/* End Page Banner Area  */}

            {/* Start Portfolio Detail Area  */}
            <section className="portfolio-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="main-image">
                                <img src={photo.url} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex">
                            <div className="portfolio-detail-intro" style={{width : "100%"}}>
                                <span className="sub-heading">Makeen Suboh</span>
                                <h3>
                                    {photo.title}

                                </h3>
                                <p>
                                    {photo.desc}

                                </p>
                                <a href={photo.website} className="link" target="_blank">
                                    <img className="svg" src={arrow_icon} alt="" />
                                    <span>موقع</span>
                                    <i className="fas fa-globe"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Portfolio Detail Area  */}

            {/* Start Portfolio Slider Area  */}
            {/* <section className="slider-area">
                <div className="container">
                    <Slider {...settings}>
                        <div className="item">
                            <img src={portfolio1} alt="" />
                        </div>

                        <div className="item">
                            <img src={portfolio2} alt="" />
                        </div>

                        <div className="item">
                            <img src={portfolio3} alt="" />
                        </div>

                        <div className="item">
                            <img src={portfolio1} alt="" />
                        </div>
                        <div className="item">
                            <img src={portfolio2} alt="" />
                        </div>

                        <div className="item">
                            <img src={portfolio3} alt="" />
                        </div>

                        <div className="item">
                            <img src={portfolio1} alt="" />
                        </div>

                        <div className="item">
                            <img src={portfolio2} alt="" />
                        </div>
                    </Slider>
                </div>
            </section> */}
            {/* End Portfolio Slider Area  */}
            {/* <FooterForm /> */}
            <FooterTwo />
        </Fragment>
    );
};

export default PortfolioDetails;
