import React, { Fragment } from "react";

const PageBanner = () => {
    return (
        <Fragment>
            {/* Start Page Banner Area  */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>about me</h1>
                            <div className="breadcrumb-box">
                                <div className="breadcrumb-intro">
                                    <p>
                                        <a href="/">HOME </a> - ABOUT
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Banner Area  */}
        </Fragment>
    );
};
export default PageBanner;
