import React, { Fragment } from "react";

import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import FooterTwo from "../components/Footer/FooterTwo";
import PortfolioTwo from "../components/PortfolioTwo/PortfolioTwo";

const HomePageTwo = () => {
    return (
        <Fragment>
            <HeaderNavigation />
            <PortfolioTwo />
            <FooterTwo />
        </Fragment>
    );
};

export default HomePageTwo;
