import React, { Fragment } from "react";

import logo_light from "../../assets/images/logo-light.png";

const FooterTwo = () => {
  return (
    <Fragment>
      {/* Start Footer Two Area  */}
      <footer className="footer-two">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-logo">
                <a href="/">
                  <span>مكين صبح</span>
                </a>
              </div>
            </div>
          </div>
          <div className="footer-inner row">
            <div className="col-md-4 col-lg-3">
              <div className="footer-widget">
                <span className="widget-icon">
                  <i className="far fa-envelope"></i>
                </span>
                <p>
                  هل لديك مشروع؟ دعنا <br />
                  نتحدث عنه
                </p>
                <a href="/">إرسال رسالة</a>
              </div>
            </div>
            <div className="col-md-4 col-lg-6">
              <div className="footer-widget">
                <div className="footer-social-light">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/dow.king">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/makeenkayan/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://kayanvip.com">
                        <i className="fas fa-globe"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/dr-makeen-suboh-1741782a/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@makeensuboh">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <p>
                  هل لديك مشروع؟ دعنا <br />
                  نتحدث عنه
                </p>
                <span className="footer-line"></span>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="footer-widget">
                <span className="widget-icon">
                  <i className="fas fa-phone"></i>
                </span>
                <p>
                  تحدث عن مشروعك 
                  الرائع
                </p>
                <a href="/">00201143258888</a>
              </div>
            </div>
          </div>
          {/* Start Footer Copyright  */}
          <div className="footer-bottom">
            <p className="copyright light">
              بإدارة <i className="fas fa-heart"></i> بواسطة{" "}
              <a href="/">فيستاويب</a> |{" "}
              <a href="https://linktr.ee/makeensuboh">لينك تري</a>{" "}
            </p>
          </div>
          {/* Start Footer Copyright  */}
        </div>
      </footer>
      {/* End Footer Two Area  */}
    </Fragment>
  );
};
export default FooterTwo;
