import axios from "axios";
import { createContext, useEffect, useState } from "react";


const AppContext = createContext();

export const AppContextProvider = (props) => {
    const { children } = props;
    let [categories, setCategories] = useState([]);
    let [photos, setpPhotos] = useState([]);
    const homeProfiles = async () => {
        try {
            const res = await axios.get("https://makeen.daimooma.com/categories");
            console.log(res.data);
            setCategories(res.data);
        } catch (err) {
            console.error("Error fetching categories:", err);
        }
    };

    useEffect(() => {
        homeProfiles();
    }, []);

    const pictures = async () => {
        try {
            const res = await axios.get("https://makeen.daimooma.com/photos");
            console.log(res.data);
            setpPhotos(res.data);
        } catch (err) {
            console.error("Error fetching photos:", err);
        }
    };

    useEffect(() => {
        pictures();
    }, []);
    return (
        <AppContext.Provider value={{ categories, photos }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;