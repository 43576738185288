import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";

import autoAnimate from "@formkit/auto-animate";
import { Link } from "react-router-dom";

import one from "../../assets/images/portfolio/design-1.jpg";
import two from "../../assets/images/portfolio/design-2.jpg";
import three from "../../assets/images/portfolio/design-3.jpg";
import four from "../../assets/images/portfolio/design-4.jpg";
import five from "../../assets/images/portfolio/design-5.jpg";
import six from "../../assets/images/portfolio/design-6.jpg";
import seven from "../../assets/images/portfolio/design-7.jpg";
import eight from "../../assets/images/portfolio/design-8.jpg";
import nine from "../../assets/images/portfolio/design-9.jpg";
import ten from "../../assets/images/portfolio/design-10.jpg";
import eleven from "../../assets/images/portfolio/design-11.jpg";
import twelve from "../../assets/images/portfolio/design-12.jpg";
import arrow_icon from "../../assets/images/icon/arrow.svg";
import makeenImg from "../../assets/images/makeen.PNG";
import axios from "axios";
import AppContext from "../../context/AppContext";
const DesignItems = [
  {
    images: makeenImg,
    category: "Consulting",
    title: "Founder of Kayan",
    type: "web",
    id: "1",
  },
  {
    images: two,
    category: "Developer",
    title: "demo number one",
    type: "web",
    id: "2",
  },
  {
    images: three,
    category: "Freelancer",
    title: "4D Tech Keyboård",
    type: "web",
    id: "3",
  },
  {
    images: four,
    category: "Freelancer",
    title: "still life photography",
    type: "des",
    id: "4",
  },
  {
    images: five,
    category: "Freelancer",
    title: "The Language of Developer",
    type: "des",
    id: "5",
  },
  {
    images: six,
    category: "Freelancer",
    title: "The new Thinking for Design",
    type: "soft",
    id: "6",
  },
  {
    images: seven,
    category: "Freelancer",
    title: "Thinking for Design",
    type: "soft",
    id: "7",
  },
  {
    images: eight,
    category: "Freelancer",
    title: "Thinking for Design",
    type: "soft",
    id: "8",
  },
  {
    images: nine,
    category: "Freelancer",
    title: "still life photography",
    type: "soft",
    id: "9",
  },
  {
    images: ten,
    category: "Freelancer",
    title: "The Language of Developer",
    type: "des",
    id: "10",
  },
  {
    images: eleven,
    category: "Freelancer",
    title: "The new Thinking for Design",
    type: "des",
    id: "11",
  },
  {
    images: twelve,
    category: "Freelancer",
    title: "Thinking for Design",
    type: "des",
    id: "12",
  },
];

const PortfolioOne = () => {
  const parent = useRef(null);
  const { categories, photos } = useContext(AppContext);
  console.log(photos);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const [menuProducts, setMenuProducts] = useState(DesignItems);
  const [activeFilter, setActiveFilter] = useState("all");

  const [filteredPhotos, setFilteredPhotos] = useState(photos);
  // const filter = (type) => {
  //     setActiveFilter(type);
  //     if (type === "all") {
  //         setMenuProducts(DesignItems);
  //     } else {
  //         setMenuProducts(DesignItems.filter((product) => product.type === type));
  //     }
  // };

  const filterPhotos = (categoryId) => {
    setActiveFilter(categoryId);

    if (categoryId === "all") {
      setFilteredPhotos(photos);
    } else {
      const filtered = photos.filter(
        (photo) => photo.category_id === categoryId
      );

      if (filtered.length === 0) {
        setFilteredPhotos(photos);
      } else {
        setFilteredPhotos(filtered);
      }
    }
  };
  return (
    <Fragment>
      {/* Start Portfolio Filter Style One Area  */}
      <div className="portfolio-section">
        <div className="custom-container">
          {/* Start Portfolio Filter Navigatopm Area  */}
          <ul className="portfolio-filter-btn">
            <li
              className={activeFilter === "all" ? "filter active" : "filter"}
              onClick={() => filterPhotos("all")}
            >
              All
            </li>
            {categories.map((c) => {
              return (
                <li
                  className={
                    activeFilter === c.name ? "filter active" : "filter"
                  }
                  onClick={() => filterPhotos(c.id)}
                  key={c.id}
                >
                  {c.name}
                </li>
              );
            })}

            {/* <li
                            className={activeFilter === "des" ? "filter active" : "filter"}
                            onClick={() => filter("des")}
                        >
                            Design
                        </li>
                        <li
                            className={activeFilter === "soft" ? "filter active" : "filter"}
                            onClick={() => filter("soft")}
                        >
                            Software
                        </li> */}
          </ul>
          {/* End Portfolio Filter Navigatopm Area  */}

          <div className="row portfolio-content" ref={parent}>
            {(filteredPhotos.length > 0 ? filteredPhotos : photos).map((p) => (
              <div className="col-sm-6 col-lg-3" key={p.id}>
                <Link to={`/portfolio/${p.id}`} className="single-gallery">
                  <div className="single-inner" style={{ height: "300px" }}>
                    <img src={p.url} alt="" style={{ height: "90%" }} />
                    <div className="portfolio-hover">
                      <img className="svg" src={arrow_icon} alt="" />
                      <h3>{p.title}</h3>
                      <h6>{p.desc}</h6>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Portfolio Filter Style One Area  */}
    </Fragment>
  );
};

export default PortfolioOne;
