import React, { useContext } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import AppRoute from "./Router/AppRouter";
import axios from "axios";
import { AppContextProvider } from "./context/AppContext";
function App() {
    
    return (
        <AppContextProvider>
        <Router>
            <AppRoute />
        </Router>
        </AppContextProvider>
    );
}

export default App;
