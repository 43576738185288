import React, { Fragment } from "react";

const FooterForm = () => {
    return (
        <Fragment>
            <div className="contact-area">
                <div className="container">
                    <div className="section-heading">
                        <h3>LET’S TALK</h3>
                        <p>ABOUT YOUR NEXT AWESOME PROJECT!</p>
                    </div>
                    {/* Start Footer Form  */}
                    <div className="contact-form">
                        <form id="contact-form" action="" method="">
                            <div className="row">
                                <div className="col-md-6">
                                    <span>I am</span>
                                </div>
                                <div className="col-md-6 position-relative">
                                    <input type="text" placeholder="( type your name here )" name="name" />
                                    <span className="underline"></span>
                                </div>
                                <div className="col-md-6">
                                    <span>I’m from</span>
                                </div>
                                <div className="col-md-6 position-relative">
                                    <input
                                        type="text"
                                        placeholder="( Company / Country / State )"
                                        name="company"
                                    />
                                    <span className="underline"></span>
                                </div>
                                <div className="col-md-6">
                                    <span>I have a project about</span>
                                </div>
                                <div className="col-md-6 position-relative">
                                    <input
                                        type="text"
                                        placeholder="( What’s Your Project? )"
                                        name="project"
                                    />
                                    <span className="underline"></span>
                                </div>
                                <div className="col-md-6">
                                    <span>It’s need to be done within a timeframe of</span>
                                </div>
                                <div className="col-md-6 position-relative">
                                    <input type="text" placeholder="( days or months )" name="time" />
                                    <span className="underline"></span>
                                </div>
                                <div className="col-md-6">
                                    <span>My estimeated budget is</span>
                                </div>
                                <div className="col-md-6">
                                    <select name="amount">
                                        <option value="1">Select amount</option>
                                        <option value="2">Lower than $200bUSD</option>
                                        <option value="3">$200 USD</option>
                                        <option value="4">$1000 USD</option>
                                        <option value="5">$5000 USD</option>
                                        <option value="6">$10000 USD</option>
                                        <option value="7">more than this</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <span>You can connect me through</span>
                                </div>
                                <div className="col-md-6 position-relative">
                                    <input type="text" placeholder="( type your email here )" name="email" />
                                    <span className="underline"></span>
                                </div>
                                <div className="col-12 submit-wrapper">
                                    <button className="submit" type="submit">
                                        Send
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* End Footer Form  */}
                </div>
            </div>
        </Fragment>
    );
};
export default FooterForm;
