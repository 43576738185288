import React, { Fragment, useState, useRef, useEffect } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import autoAnimate from "@formkit/auto-animate";

import one from "../../assets/images/portfolio/c1.jpg";
import two from "../../assets/images/portfolio/c2.jpg";
import three from "../../assets/images/portfolio/c3.jpg";
import four from "../../assets/images/portfolio/c4.jpg";
import five from "../../assets/images/portfolio/c5.jpg";
import six from "../../assets/images/portfolio/c6.jpg";
import seven from "../../assets/images/portfolio/c7.jpg";
import eight from "../../assets/images/portfolio/c8.jpg";
import link_icon from "../../assets/images/icon/link.svg";

const DesignItems = [
    {
        images: one,
        category: "CINEMA 4D | AFTER EFFECTS | SPEEDGRADE",
        title: "SPORTS NEWS FILLER",
        type: "web",
        id: "1",
    },
    {
        images: two,
        category: "Intro 4D | AFTER EFFECTS | SPEEDGRADE",
        title: "Intro for news",
        type: "web",
        id: "2",
    },
    {
        images: three,
        category: "advertise 4D | AFTER EFFECTS",
        title: "advertisement for shoe",
        type: "web",
        id: "3",
    },
    {
        images: four,
        category: "CINEMA 4D | AFTER EFFECTS | SPEEDGRADE",
        title: "SPORTS NEWS FILLER",
        type: "des",
        id: "4",
    },
    {
        images: five,
        category: "game 2D | AFTER EFFECTS | psd",
        title: "SPORTS shoes",
        type: "des",
        id: "5",
    },
    {
        images: six,
        category: "CINEMA 4D | AFTER EFFECTS | SPEEDGRADE",
        title: "SPORTS NEWS FILLER",
        type: "soft",
        id: "6",
    },
    {
        images: seven,
        category: "CINEMA 4D | AFTER EFFECTS | SPEEDGRADE",
        title: "SPORTS NEWS FILLER",
        type: "soft",
        id: "7",
    },
    {
        images: eight,
        category: "Freelancer",
        title: "Thinking for Design",
        type: "soft",
        id: "8",
    },
];

const PortfolioThree = () => {
    const parent = useRef(null);
    const [activeFilter, setActiveFilter] = useState("all");

    useEffect(() => {
        parent.current && autoAnimate(parent.current);
    }, [parent]);

    const [MenuProducts, setMenuProducts] = useState(DesignItems);
    const filter = (type) => {
        setActiveFilter(type);
        setMenuProducts(DesignItems.filter((product) => product.type === type));
    };

    return (
        <Fragment>
            {/* Start Portfolio Filter Style Three Area  */}
            <div className="portfolio-section">
                <div className="custom-container">
                    {/* Start Portfolio Filter Navigatopm Area  */}
                    <ul className="portfolio-filter-btn">
                        <li
                            className={activeFilter === "all" ? "filter active" : "filter"}
                            onClick={() => filter("all")}
                        >
                            All
                        </li>
                        <li
                            className={activeFilter === "web" ? "filter active" : "filter"}
                            onClick={() => filter("web")}
                        >
                            Website
                        </li>
                        <li
                            className={activeFilter === "des" ? "filter active" : "filter"}
                            onClick={() => filter("des")}
                        >
                            Design
                        </li>
                        <li
                            className={activeFilter === "soft" ? "filter active" : "filter"}
                            onClick={() => filter("soft")}
                        >
                            Software
                        </li>
                    </ul>
                    {/* End Portfolio Filter Navigatopm Area  */}
                    <div className="row g-0 mt-4" ref={parent}>
                        {MenuProducts.map((product, i) => (
                            <div key={product.id} className="col-lg-6">
                                <div className="single-portfolio-3 four ">
                                    <img src={product.images} alt="" />
                                    <div className="portfolio-hover-3">
                                        <h3>{product.title}</h3>
                                        <h6>{product.category}</h6>
                                        <div className="line"></div>

                                        <Link to={`/portfolio`}>
                                            <img className="svg" src={link_icon} alt="" />
                                            view detail
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Portfolio Filter Style Three Area  */}
        </Fragment>
    );
};

export default PortfolioThree;
