import React, { Fragment } from "react";

import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import PortfolioOne from "../components/PortfolioOne/PortfolioOne";
import FooterForm from "../components/Footer/FooterForm";
import FooterTwo from "../components/Footer/FooterTwo";

const HomePage = () => {
    return (
        <Fragment>
            <HeaderNavigation />
            <PortfolioOne />
            {/* <FooterForm /> */}
            <FooterTwo />
        </Fragment>
    );
};

export default HomePage;
