import React, { Fragment } from "react";

const FooterOne = () => {
  return (
    <Fragment>
      {/* Start Footer One Area  */}
      <footer className="footer-area">
        <div className="footer-social">
          <ul>
            <li>
              <a href="https://www.facebook.com/dow.king">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/makeenkayan/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://kayanvip.com">
                <i className="fas fa-globe"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/dr-makeen-suboh-1741782a/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@makeensuboh">
                <i className="fab fa-youtube"></i>
              </a>
            </li>
          </ul>
        </div>


        {/* Start Footer Copyright  */}
        <p className="copyright">
          MANAGED WITH <i className="fas fa-heart"></i> BY{" "}
          <a href="/">VISTAWEB</a> | <a href="https://linktr.ee/makeensuboh">Linktree</a>{" "}
        </p>
        {/* End Footer Copyright  */}
      </footer>
      {/* Start Footer One Area  */}
    </Fragment>
  );
};
export default FooterOne;
