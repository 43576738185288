import React, { Fragment, useState, useRef, useEffect } from "react";

import autoAnimate from "@formkit/auto-animate";
import { Link } from "react-router-dom";

import one from "../../assets/images/portfolio/b1.jpg";
import two from "../../assets/images/portfolio/b2.jpg";
import three from "../../assets/images/portfolio/b3.jpg";
import four from "../../assets/images/portfolio/b4.jpg";
import five from "../../assets/images/portfolio/b5.jpg";
import six from "../../assets/images/portfolio/b6.jpg";
import seven from "../../assets/images/portfolio/b7.jpg";
import eight from "../../assets/images/portfolio/b8.jpg";
import nine from "../../assets/images/portfolio/b9.jpg";
import ten from "../../assets/images/portfolio/b10.jpg";
import eleven from "../../assets/images/portfolio/b11.jpg";
import twelve from "../../assets/images/portfolio/b12.jpg";

const DesignItems = [
    {
        images: one,
        category: "Development",
        title: "4D Tech Keyboård",
        type: "web",
    },
    {
        images: two,
        category: "Developer",
        title: "demo number one",
        type: "web",
    },
    {
        images: three,
        category: "Freelancer",
        title: "4D Tech Keyboård",
        type: "web",
    },
    {
        images: four,
        category: "Freelancer",
        title: "still life photography",
        type: "des",
    },
    {
        images: five,
        category: "Freelancer",
        title: "The Language of Developer",
        type: "des",
    },
    {
        images: six,
        category: "Freelancer",
        title: "The new Thinking for Design",
        type: "soft",
    },
    {
        images: seven,
        category: "Freelancer",
        title: "Thinking for Design",
        type: "soft",
    },
    {
        images: eight,
        category: "Freelancer",
        title: "Thinking for Design",
        type: "soft",
    },
    {
        images: nine,
        category: "Freelancer",
        title: "still life photography",
        type: "soft",
    },
    {
        images: ten,
        category: "Freelancer",
        title: "The Language of Developer",
        type: "des",
    },
    {
        images: eleven,
        category: "Freelancer",
        title: "The new Thinking for Design",
        type: "des",
    },
    {
        images: twelve,
        category: "Freelancer",
        title: "Thinking for Design",
        type: "des",
    },
];

const PortfolioTwo = () => {
    const parent = useRef(null);
    const [activeFilter, setActiveFilter] = useState("all");

    useEffect(() => {
        parent.current && autoAnimate(parent.current);
    }, [parent]);

    const [menuProducts, setMenuProducts] = useState(DesignItems);

    const filter = (type) => {
        setActiveFilter(type);
        setMenuProducts(DesignItems.filter((product) => product.type === type));
    };

    return (
        <Fragment>
            {/* Start Portfolio Filter Style Two Area  */}
            <div className="portfolio-section two">
                <div className="custom-container">
                    {/* Start Portfolio Filter Navigatopm Area  */}
                    <ul className="portfolio-filter-btn">
                        <li
                            className={activeFilter === "all" ? "filter active" : "filter"}
                            onClick={() => {
                                setActiveFilter("all");
                                setMenuProducts(DesignItems);
                            }}
                        >
                            All
                        </li>
                        <li
                            className={activeFilter === "web" ? "filter active" : "filter"}
                            onClick={() => filter("web")}
                        >
                            Website
                        </li>
                        <li
                            className={activeFilter === "des" ? "filter active" : "filter"}
                            onClick={() => filter("des")}
                        >
                            Design
                        </li>
                        <li
                            className={activeFilter === "soft" ? "filter active" : "filter"}
                            onClick={() => filter("soft")}
                        >
                            Software
                        </li>
                    </ul>
                    {/* End Portfolio Filter Navigatopm Area  */}

                    <div className="row portfolio-content" ref={parent}>
                        {menuProducts.map((product, i) => (
                            <div className="col-sm-6 col-lg-3" key={product.images}>
                                <Link to={`/portfolio`} className="single-portfolio-2 two">
                                    <div className="single-inner">
                                        <img src={product.images} alt="" />
                                        <div className="portfolio-hover-2">
                                            <h3>{product.title}</h3>
                                            <h6>{product.category}</h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Portfolio Filter Style Two Area  */}
        </Fragment>
    );
};

export default PortfolioTwo;
