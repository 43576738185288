import React, { Component, Fragment } from "react";
import img_url_one from "../../assets/images/1674031669214.jpeg";

class About extends Component {
  render() {
    return (
      <Fragment>
        <section className="about-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <img src={img_url_one} alt="" />
              </div>
              <div className="col-lg-8 ">
                <div className="about-dtails-wrapper">
                  <div className="about-dtails">
                    <h6>قصتي </h6>
                    <p>
                      عاشق للقراءة ، شغوف للقلم والورقة ، يـتـفـنّـن في إظهار
                      الحروف بخطه العربي بطريقة إبداعية ، يبحث باستمرار عن حرف
                      "الياء" الغير مُنقط ، ليكتب تلك النـقـطـتـيـن تحته ، وهو
                      ما يرمز إلى اهتمامه بالتفاصيل وحُـبه لجمال اللوحة الكاملة
                      ، ويقابل شغفه بالخط العربي اهتمامه بصياغة الأسلوب اللغوي،
                      وهو ما قاده بطبيعة الحال إلى منصبه الحالي كـ "أمين عام
                      للاتحاد الدولي للصحافة والإعلام" - المملكة المتحدة. يشغل
                      مكين صبح اليوم منصب الرئيس التنفيذي والمؤسس لـ "مجموعة
                      كيان للحول الإدارية والتسويق" والتي أنجبت له 3 أولاد هم :
                      كيان سوشيال – كيان أكاديمي – كيان بزنس ، فاتحاً طريق
                      النجاح من إسطنبول لِـ دبي ثم القاهرة ثم عمّان و آربيل ،
                      باحثاً عن أصحاب المشاريع والأفكار الحديثة ، ليكون معهم
                      كتفاً لكتف وُصولاً لطريق الخبرة الحقيقي للبزنس في أسرع وقت
                      ، حيث ساهمت قيادته مؤخراً في تحويل أكثر من ١٨ فكرة بسيطة ،
                      لمشاريع فوق المُتوسطة للكبيرة ، ونقلهم من المشاريع النمطية
                      للمشاريع الذكية ، ليساهم في تأسيس "المنتدى الاقتصادي لرجال
                      الأعمال العرب" ، والذي انطلق في الولايات المتحدة الامريكية
                      ، ليكون بذلك من أوائل ريادي الأعمال الذين أسسوا حاضنات
                      ومُسرعات الأعمال في القاهرة ، ليستكمل فكرته التي يتحدث
                      عنها دوماً وهي إنشاء "نادي الأعمال الذكي" . أنجبت "مجموعة
                      كيان" تحت اسمها العديد من المشاريع ، منها موقع إخباري حديث
                      يدعى "كيان نيوز" وموقع خاص بالعقارات في القاهرة الجديدة
                      يدعى "كيان عقارات". تعاون مكين صبح مع منصة TED في القاهرة
                      ، لدمج مشروعة الخاص بالمراهقين مع المشروع الخاص بالأطفال
                      TEDx Kids، من خلال المنصة الخاصة به "TeenzHUB"، و التي
                      تستهدف المراهقين، كما أسس لمشروع أكاديمي يجمع بين التعليم
                      والبزنس في منصة واحدة أطلق عليها " AdulTTeen". يقف مكين
                      صبح أيضاً وراء المبادرة العربية الجديدة كُـلـيـاً والتي
                      أطلق عليها اسم "كراكيب"، وهي منصة مُخصصة لتبادل الأشياء
                      الغير مُستعملة على المستوى الشخصي والتي استحوذت عليها
                      الذكريات بشكل مُطلق ، وقريباً ستـتـبـادل الناس في الوطن
                      العربي ذكرياتهم ليعيشوا تجـربـة جديدة وهي "ثقافة التخلي
                      الإيجابية" ، مما يعكس إبداعه في تلبية احتياجات العالم
                      الحقيقي. أو ما نسميه "رجل الشارع" بتميز . عضو في جمعية
                      المحللين المصريين – الدقي ، والتي تخص بتعليم التحليل الفني
                      في أسواق المال ، حيث تحظى خبرته في عالم البورصات والتداول
                      بسمعة واسعة على المستوى العربي والدولي. يؤسس اليوم مكين
                      صبح "امبراطورية ذهبية" سيجمع فيها كل تلك الخبرات وكل تلك
                      الشراكات وكل تلك الشخوص ، لتكون مُلهمة للكثير من الباحثين
                      عن فكرة مشروع أو عن خبرة بزنس ، أو عن شراكات حقيقية
                      وفعّالة ، ومع استكمال كتابته لروايته الشخصية الأولى "حكمة
                      أربعيني" ليحقق حلمه القديم الجديد ويستكمل نضجه الفكري بحسب
                      قوله . عاش مكين صبح تحت رضا والدته وكانت الدنيا هي والده ،
                      تنقل بين أماكن جغرافية مُتنوعة، مما أثرث ثقافات الأماكن
                      على مكنونات مصطلحاته اللغوية ، فيقول مكين صبح عن نفسه *لقد
                      شربت من ماء النيل، وأكلت من تمر العراق، وصليت في الجامع
                      الأموي بدمشق ، وأمضيت ذكريات كثيرة على حدود البلدان ، وأنا
                      ابن معركة اليرموك وابن التراب الهاشمي الأحمر وبن أحد عجائب
                      الدنيا السبع ....مكين صبح وأخيراً ... لقد أثرت تجاربه
                      وأسفاره عبر العالم العربي على تطوير فكره بشكل دائم، مما
                      جعله ليس فقط رجل أعمال ناجح، بل أيضاً مُـتـذوقاً
                      ثـقـافـيـاً مُرتبطاً بعروبته يبحث من جديد عن ورقة وطن يكتب
                      عليها "حكمة أربعيني.
                    </p>

                    {/* <div className="about-social footer-social">
                                            <ul>
                                                <li>
                                                    <a href="/">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <i className="fab fa-behance"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <i className="fab fa-linkedin-in"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <i className="fab fa-youtube"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
export default About;
