import React, { Fragment } from "react";

import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import FooterForm from "../components/Footer/FooterForm";
import FooterOne from "../components/Footer/FooterOne";
import PortfolioThree from "../components/PortfolioThree/PortfolioThree";

const HomePageThree = () => {
    return (
        <Fragment>
            <HeaderNavigation />
            <PortfolioThree />
            <FooterForm />
            <FooterOne />
        </Fragment>
    );
};

export default HomePageThree;
