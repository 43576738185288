import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "../pages/AboutPage";
import HomePage from "../pages/HomePage";
import HomePageThree from "../pages/HomePageThree";
import HomePageTwo from "../pages/HomePageTwo";
import PortfolioPage from "../pages/Portfolio";
import PortfolioDetails from "../components/PortfolioDetails/PortfolioDetails";

const AppRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} exact />
            <Route path={`${process.env.PUBLIC_URL}/`} element={<HomePage />} exact />
            <Route path={`${process.env.PUBLIC_URL}/about`} element={<About />} exact />
            <Route path={`${process.env.PUBLIC_URL}/hometwo`} element={<HomePageTwo />} exact />
            <Route path={`${process.env.PUBLIC_URL}/portfolio/:id`} element={<PortfolioDetails />} exact />
            <Route path={`${process.env.PUBLIC_URL}/homethree`} element={<HomePageThree />} exact />
        </Routes>
    );
};

export default AppRoute;
