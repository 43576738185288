import React, { Fragment } from "react";

import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import FooterForm from "../components/Footer/FooterForm";

import PageBanner from "../components/PageBanner/PageBanner";
import About from "../elements/About/About";
import FooterTwo from "../components/Footer/FooterTwo";

const AboutPage = () => {
    return (
        <Fragment>
            <HeaderNavigation />
            <PageBanner />
            <About />
            {/* <FooterForm /> */}
            <FooterTwo />
        </Fragment>
    );
};

export default AboutPage;
